import styled from 'styled-components';
import { Container, Col } from 'reactstrap';

export const BannerPrincipal = styled.header`
  background-color: #453a34;
  height: 586.41px;
  width: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  top: -10;
  left: 0;
  background-color: #212529;
  opacity: 0.5;
  height: 586.41px;
  width: 100%;
`;

export const BannerImg = styled.img`
  position: absolute;
  top: -10;
  left: 0;
`;

export const H1 = styled.h1`
  color: #fff;
  font-size: 4.2rem;
  line-height: 110%;
  margin: 2.8rem 0 1.68rem 0;
`;

export const H5 = styled.h5`
  color: #fff;
  font-size: 1.35rem;
  line-height: 110%;
  margin: 1.0933333333rem 0 0.656rem 0;
`;

export const ButtonBemVindo = styled.a``;

export const ContainerEquipe = styled(Container)`
  text-align: center;
`;

export const Coll = styled(Col)`
  text-align: center;
  padding-top: 183px;
`;

export const TextoPersonalizado = styled.p`
  color: #868e96;
  font-size: 16px;
`;

export const Espaco = styled.div`
  padding-top: 20px;
`;

export const SocialButton = styled.ul`
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  list-style: none;
`;

export const ListInlineItem = styled.li`
  margin-right: 0.5rem;
  display: inline-block;
  padding: 15px;
`;

export const ServiceHeading = styled.h4`
  margin: 15px 0;
  text-transform: none;
  font-weight: 700;
`;

export const Fundo = styled.div`
  background-color: #f8f9fa;
  padding: 100px 0;
`;

export const ContainerFluid = styled.div`
  max-width: 1920px;
  padding: 0 !important;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

export const PortfolioBox = styled.a`
  position: relative;
  display: block;
  color: #453a34;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #453a34;
    text-decoration: underline;
  }
`;

export const ImgFluid = styled.img`
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
`;

export const PortfolioBoxCaption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  opacity: 0;
  color: #fff;
  background: #453a34d6;
  transition: opacity 0.25s ease;
  text-align: center;

  &:hover {
    opacity: 1;
  }
`;

export const ProjectCategory = styled.div`
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5) !important;
`;

export const ProjectName = styled.div`
  font-size: 1.2rem;
`;
