import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #fff;
    color: rgb(134, 142, 150);
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, a, p {
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
    font-family: 'Roboto Slab', serif;
  }

  button {
    cursor: pointer;
  }
`;
