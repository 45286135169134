import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import {
  FaReact,
  FaHtml5,
  FaAndroid,
  FaBootstrap,
  FaYarn,
  FaDocker,
  FaGithub,
  FaNodeJs,
  FaLinux,
  FaAngular,
  FaCss3Alt,
  FaSass,
  FaTrophy,
  FaEye,
  FaPuzzlePiece,
} from 'react-icons/fa';

import banner from '../../assets/banner-principal.jpg';

import alloc from '../../assets/alloc.png';
import landing from '../../assets/lading.png';
import ezshop from '../../assets/ezshop.png';
import mrconsorcio from '../../assets/mrconsorcio.png';
import simulador from '../../assets/simulador.png';
import inker from '../../assets/inker.png';
import inforchannel from '../../assets/inforchannel.png';
import nissin from '../../assets/nissin.png';
import cicloo from '../../assets/cicloo.png';

import Menu from '../../components/Menu';
import Footer from '../../components/Footer';

import './styles.css';

import {
  BannerPrincipal,
  Overlay,
  H1,
  ButtonBemVindo,
  Coll,
  TextoPersonalizado,
  ContainerEquipe,
  Espaco,
  SocialButton,
  ListInlineItem,
  BannerImg,
  ServiceHeading,
  Fundo,
  ContainerFluid,
  PortfolioBox,
  ImgFluid,
  PortfolioBoxCaption,
  ProjectCategory,
  ProjectName,
} from './styles';

const Home: React.FC = () => {
  return (
    <>
      <Menu />
      <BannerPrincipal id="inicio">
        <BannerImg src={banner} height="586.41px" width="100%" />
        <Overlay />
        <Container>
          <Row>
            <Coll lg="8" md="10" mx="auto">
              <H1>Tiramos sua idéia do papel</H1>
              <ButtonBemVindo
                className="btn btn-lg btn-success"
                href="https://api.whatsapp.com/send?phone=55169981645973"
                target="_blank"
              >
                Faça seu orçamento
              </ButtonBemVindo>
            </Coll>
          </Row>
        </Container>
      </BannerPrincipal>
      <Fundo id="sobre">
        <Container id="sobre">
          <Row>
            <Col lg="1" mr="auto" />
            <Col lg="9" mr="auto">
              <TextoPersonalizado>
                Devnow é um laboratorio de desenvolvimento e inovação, que tem
                como intuito o desenvolvimento dos mais diversos sistemas sendo
                ele mobile, web ou até desktop.
              </TextoPersonalizado>
              <TextoPersonalizado>
                Somos uma empresa brasileira que tem como missão tornar suas
                idéias em realidade.
              </TextoPersonalizado>
              <TextoPersonalizado>
                E também apoiamos a comunidade de desenvolvimento atraves da
                dissiminação de conhecimento, tudo para auxiliar os novos
                desenvolvedores.
              </TextoPersonalizado>
            </Col>
          </Row>
        </Container>
      </Fundo>
      <Container id="skills">
        <Espaco />
        <Row>
          <Col md="12">
            <SocialButton>
              <ListInlineItem>
                <FaReact size={50} color="#61dbfb" />
              </ListInlineItem>
              <ListInlineItem>
                <FaHtml5 size={50} color="#E44D26" />
              </ListInlineItem>
              <ListInlineItem>
                <FaCss3Alt size={50} color="#264de4" />
              </ListInlineItem>
              <ListInlineItem>
                <FaSass size={50} color="#CD6799" />
              </ListInlineItem>
              <ListInlineItem>
                <FaAndroid size={50} color="#3DDC84" />
              </ListInlineItem>
              <ListInlineItem>
                <FaBootstrap size={50} color="#080135" />
              </ListInlineItem>
              <ListInlineItem>
                <FaYarn size={50} color="#2188b6" />
              </ListInlineItem>
              <ListInlineItem>
                <FaDocker size={50} color="#0db7ed" />
              </ListInlineItem>
              <ListInlineItem>
                <FaGithub size={50} color="#000" />
              </ListInlineItem>
              <ListInlineItem>
                <FaNodeJs size={50} color="#68A063" />
              </ListInlineItem>
              <ListInlineItem>
                <FaLinux size={50} color="#000" />
              </ListInlineItem>
              <ListInlineItem>
                <FaAngular size={50} color="#B52E31" />
              </ListInlineItem>
            </SocialButton>
          </Col>
        </Row>
        <Espaco />
      </Container>
      <Fundo>
        <ContainerEquipe id="metas">
          <Row>
            <Col md="4">
              <FaTrophy size={60} color="#ffab40" />
              <ServiceHeading>Missão</ServiceHeading>
              <TextoPersonalizado>
                Prover Soluções Tecnologicas na area de tecnolgia mais adequadas
                para o alcance dos objetivos de empresas e o atendimento das
                necessidades dos clientes.
              </TextoPersonalizado>
            </Col>
            <Col md="4">
              <FaEye size={60} color="#4caf50" />
              <ServiceHeading>Visão</ServiceHeading>
              <TextoPersonalizado>
                Ser referência nacional no segmento de tecnolgia pela
                eficiência, eficácia e inovação nas nossas Soluções em
                Tecnologicas e sistemas.
              </TextoPersonalizado>
            </Col>
            <Col md="4">
              <FaPuzzlePiece size={60} color="#f11c0c" />
              <ServiceHeading>Valores</ServiceHeading>
              <TextoPersonalizado>
                Eficiência - Proatividade - Valorização humana - Preocupação com
                a satisfação do cliente - Postura colaborativa - Transparência -
                Comprometimento-Criatividade.
              </TextoPersonalizado>
            </Col>
          </Row>
        </ContainerEquipe>
      </Fundo>
      <div id="portifolio">
        <ContainerFluid className="p-0">
          <Row className="no-gutters">
            <Col lg={4} sm={6}>
              <PortfolioBox href="/#portifolio">
                <ImgFluid src={alloc} />
                <PortfolioBoxCaption>
                  <ProjectCategory>
                    Consultoria para empresa Aloc
                  </ProjectCategory>
                  <ProjectName>
                    Consultoria realizada para o desenvolvimento da plataforma
                    de pdv
                  </ProjectName>
                </PortfolioBoxCaption>
              </PortfolioBox>
            </Col>
            <Col lg={4} sm={6}>
              <PortfolioBox
                href="https://thayller.github.io/landing-page/"
                target="_blank"
              >
                <ImgFluid src={landing} />
                <PortfolioBoxCaption>
                  <ProjectCategory>
                    Landing Page de demonstração
                  </ProjectCategory>
                  <ProjectName>
                    Landing page com foco na captação de clientes para empresas
                    que trabalham com vendas de planos de sáude.
                  </ProjectName>
                </PortfolioBoxCaption>
              </PortfolioBox>
            </Col>
            <Col lg={4} sm={6}>
              <PortfolioBox
                href="https://play.google.com/store/apps/details?id=ezshop.com"
                target="_blank"
              >
                <ImgFluid src={ezshop} />
                <PortfolioBoxCaption>
                  <ProjectCategory>EzShop</ProjectCategory>
                  <ProjectName>
                    Ecommerce focado em supermercado online para um mercado nos
                    EUA feito em wordpress.
                  </ProjectName>
                </PortfolioBoxCaption>
              </PortfolioBox>
            </Col>
            <Col lg={4} sm={6}>
              <PortfolioBox
                href="https://play.google.com/store/apps/details?id=com.mr.consorcios.appsimulacao"
                target="_blank"
              >
                <ImgFluid src={mrconsorcio} />
                <PortfolioBoxCaption>
                  <ProjectCategory>Mr Consórcio</ProjectCategory>
                  <ProjectName>
                    Aplicativo para empresa de consórcios, tem como foco
                    capitalizar de clientes.
                  </ProjectName>
                </PortfolioBoxCaption>
              </PortfolioBox>
            </Col>
            <Col lg={4} sm={6}>
              <PortfolioBox
                href="https://play.google.com/store/apps/details?id=br.com.mundoeco.simuladoreconomia"
                target="_blank"
              >
                <ImgFluid src={simulador} />
                <PortfolioBoxCaption>
                  <ProjectCategory>Simulador de Economia</ProjectCategory>
                  <ProjectName>
                    O simulador de economia foi criado com intuito de
                    desmistificar o uso da tecnologia em beneficio ao
                    meio-ambiente e oferecer ao usuário a possibilidade de
                    comparar o uso de papel toalha com a solução do uso de
                    secadores de mãos automático.
                  </ProjectName>
                </PortfolioBoxCaption>
              </PortfolioBox>
            </Col>
            <Col lg={4} sm={6}>
              <PortfolioBox
                href="https://play.google.com/store/apps/details?id=com.inker"
                target="_blank"
              >
                <ImgFluid src={inker} />
                <PortfolioBoxCaption>
                  <ProjectCategory>
                    Inker - Seu Celular muito mais Seguro
                  </ProjectCategory>
                  <ProjectName>
                    Assine a melhor Proteção para o seu celular. O melhor
                    atendimento, as melhores condições do mercado e você mais
                    seguro com o seu smartphone protegido pela Inker. A
                    assinatura é totalmente digital, você contrata pelo
                    aplicativo ou pelo site da Inker e em 2 minutos já está
                    concluída. Rápido e fácil!
                  </ProjectName>
                </PortfolioBoxCaption>
              </PortfolioBox>
            </Col>
            <Col lg={4} sm={6}>
              <PortfolioBox
                href="https://play.google.com/store/apps/details?id=com.inforchannel.revista"
                target="_blank"
              >
                <ImgFluid src={inforchannel} />
                <PortfolioBoxCaption>
                  <ProjectCategory>
                    Infor Channel
                  </ProjectCategory>
                  <ProjectName>
                    Acesse e receba as principais notícias do setor de Tecnologia 
                    da Informação e Telecomunicações e do seu ecossistema de 
                    distribuição de produtos e serviços do segmento. São 
                    temas relevantes que irão agregar valor aos seus 
                    conhecimentos e negócios. Faça parte dessa 
                    comunidade! Baixe nosso aplicativo e receba 
                    mensalmente uma nova edição da Revista Infor 
                    Channel.
                  </ProjectName>
                </PortfolioBoxCaption>
              </PortfolioBox>
            </Col>
            <Col lg={4} sm={6}>
              <PortfolioBox
                href="https://play.google.com/store/apps/details?id=com.nissin.calculator"
                target="_blank"
              >
                <ImgFluid src={nissin} />
                <PortfolioBoxCaption>
                  <ProjectCategory>
                  Calculadora Nissin
                  </ProjectCategory>
                  <ProjectName>
                    Aplicativo para calcular e comparar massa nissin com as tradicionais, com telas de ajuda e uma calculadora básica interna.
                  </ProjectName>
                </PortfolioBoxCaption>
              </PortfolioBox>
            </Col>
            <Col lg={4} sm={6}>
              <PortfolioBox
                href="https://play.google.com/store/apps/details?id=br.com.devpartner.unilever"
                target="_blank"
              >
                <ImgFluid src={cicloo} />
                <PortfolioBoxCaption>
                  <ProjectCategory>
                  Cicloo
                  </ProjectCategory>
                  <ProjectName>
                    Já pensou ganhar prêmios fazendo as suas compras do dia a dia e podendo trocar por prêmios para você e benefícios 
                    para sua comunidade? Com o Cicloo você encontra essa solução em um só lugar. Como funciona? 
                  </ProjectName>
                </PortfolioBoxCaption>
              </PortfolioBox>
            </Col>
          </Row>
        </ContainerFluid>
      </div>
      <Footer />
    </>
  );
};

export default Home;
